.electrical-product-Section {
  background-image: url("../../../Assets/Images/Products/EnergyBanner.png");
  background-size: 100% 100%;
  width: 100%;
  height: 400px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
  display: flex;
  align-items: center;

}

.product-section h1 {
  font-size: 40px;
  font-weight: 700;
  color: #2D67AA;
}

.product-section span {
  color: rgb(58, 196, 242);
}

.product-row {
  margin-left: 5% !important;

}


.product-section .card {
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease;
}


.card .arrow {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.arrow {
  cursor: pointer;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow i {
  text-align: center;
}

.card:hover .arrow {
  display: flex;
}

.product-section .card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(141, 141, 250, 0.5);
  z-index: 1;
}

.card-body {
  z-index: 2;
}

.card-button {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  background-color: #004a98;
  color: #fff;
  border: none;
  z-index: 5;
  cursor: pointer;
  text-align: center;
  display: block;
  text-decoration: none;
}


@media (max-width: 767px) {
  .product-section .card {
    width: 100% !important;
    height: 100%;
  }

  .product-row {
    margin-left: 0% !important;
    margin-right: 2% !important;
  }

  .brass-subheader-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}