.InnerTerminal {
    padding: 10rem 0rem 0rem;
}

.inner-img-fit {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
}

.service-block-6 h2 {
    font-weight: 700;
    color: gray;
}

@media (max-width: 767px) {
    .InnerTerminal {
        padding: 50px 0px;
    }

    .inner-img-fit {
        padding: 0px 0px 10px 0px;
    }

    .service-block-6 h2 {
        margin-bottom: 15px;
    }
}