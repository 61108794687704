.innerEarth-Section {
    background-image: url("../../../../../../Assets/Images/Products/Earthing\ Accessore\ .png");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.innerEarth-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.innerEarth-Section .innerearth-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.innerearth-subheader-content h1 {
    font-size: 50px !important;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.Access-Section-2 {
    padding: 106px 0px;
}

@media (max-width:786px) {
    .innerEarth-Section .innerearth-subheader-content {
        padding: 75px 0px 160px;
    }

    .innerearth-subheader-content h1 {
        font-size: 37px !important;
        margin-top: 14%;
        margin-bottom: 5px;
        color: #fff;
        font-weight: 600;
    }

}