/* New Contact Form Css */

.contact-form {
    position: relative;
}

.contact-form .form-group {
    position: relative;
    background: #f0f0f0;
    padding: 20px 22px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.contact-form input {
    position: relative;
    background: none;
    width: 100%;
    border: 0;
    padding: 0px;
    outline: none;
}

.contact-form input :focus {
    background-color: red !important;

}

.invalid-feed {
    color: red;
    font-size: 15px;
}

.contact-form textarea {
    position: relative;
    background: transparent;
    width: 100%;
    color: #8b8b8b;
    height: 159px;
    outline: none;
    border: none;
}

.send-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.send-btn button {
    width: 120px !important;
    padding: 10px 10px;
}

.theme-btn {
    background-color: #195eaf;
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 24px;
    color: wheat;
    padding: 18px 35px;
    font-weight: 500;
    overflow: hidden;
    text-transform: capitalize;
    vertical-align: middle;
    text-align: center;
    width: 28vw !important;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.factory-location .location iframe {
    width: 100%;
    height: 400px;
}

/* contact last section :start */
.contact-last-section .card {
    width: 90%;
    /* margin-bottom: 15px; */
    height: 100%;
    border: 1.5px solid #034794;
    min-height: max-content;
}

@media (max-width:769px) {
    .contact-last-section .card {
        width: 90%;
        /* margin-bottom: 15px; */
        height: 350px !important;
        border: 1.5px solid #034794;
    }

}

@media (max-width:913px) {
    .contact-last-section .card {
        width: 90%;
        /* margin-bottom: 15px; */
        height: 360px !important;
        border: 1.5px solid #034794;
    }

}

@media (max-width:851px) {
    .contact-last-section .card {
        width: 90%;
        /* margin-bottom: 15px; */
        height: 350px !important;
        border: 1.5px solid #034794;
    }

}

@media (max-width:500px) {
    .contact-last-section .card {
        width: 90%;
        /* margin-bottom: 15px; */
        height: 250px !important;
        border: 1.5px solid #034794;
    }

}

.card-icon-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.contact-last-section .card .card-body h5 {
    color: #034794;
    font-weight: bold;
}

.contact-last-section .card .card-body p {
    color: #034794;
    font-family: 'Roboto', sans-serif;
}

.contact-last-section .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #f1efef;
    display: flex;
    align-items: center;
    margin-top: -15%;
    justify-content: center;
}

.contact-last-section .icon i {
    color: #034794;
}



@media only screen and (max-width: 767px) {
    .contact-first-section .text-overlay {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
    }


    .contact-last-section .card {
        width: 70%;
        height: 30vh;
        min-height: max-content;
    }

    .form-group input {
        width: 100%;
        height: 30px;
        /* border: 1px solid #ced4da; */
        outline: none;
    }

    .form-group textarea {
        width: 100%;
    }

    .contact-form-col form {
        margin-left: 0%;
    }

    .contact-form-col .buttons {
        width: 100%;
    }

    .contact-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20%;
    }

    .send-btn button {
        padding: 10px 20px;
    }

}