.home-csr-img img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-top: 8.2rem;
}

.csr-section-home .text-csr {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.crs-main {
    margin-top: 70px;
}

.csr-block h2 strong {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    margin-bottom: 30px;
}

.csr-block p {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
}

.crs-inner-text {
    width: 100%;
    text-align: center;
}

.crs-img-framed {
    text-align: center;
    margin-bottom: 10px;
}

.crs-img-framed img {
    margin-bottom: 10px;
}

.crs-moto {
    padding: 50px 0px;
}

.crs-inner-text h3 {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
}

.crs-inner-text p {
    font-size: 15px;
    font-weight: 500;
    opacity: 1;
    color: #000000;
}

/* se-success */
.se-success-section {
    background-color: #f6f6f6;
    padding: 50px 0;
}
.image-section{
    margin-bottom: 40px;
}
.health-safety h4 {
    font-size: 20px;
    font-weight: 900;
    padding: 35px 0 10px 39px;
    line-height: 29px;
    color: #231F20;
}

.health-safety p {
    color: #666;
    line-height: 26px;
    padding-right: 34px;
    padding-left: 39px;
    font-size: 16px;
    font-weight: 400
}

.healthimg-fluid {
    max-width: 100%;
    height: auto;
}

/* csr-key-area Css */
.csr-img {
    width: 90%;
    height: auto;
}

.csr-key-area-heading {
    text-align: center;
    padding: 50px 50px;
}

.csr-focus-col h3 {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    text-align: center;
    padding: 13px 0px;
    padding-right: 20px;
}

/* se-commited-section */

.se-commited-section {
    background-color: #ffff;
    padding: 70px 0;
}

.se-commited-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SeReduce {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
}

.SeReduce-inner ul li {
    list-style-type: disc;
}

.SeEnvImage {
    width: 100%;
    height: auto;
}

/* our activities section start */
.our-activities-container{
    background-color:rgb(245, 245, 245);
    margin-bottom: 2rem;
}
.our-activities {
    padding: 20px 0px 0px;
}

.our-activities span {
    color: #3AC4F2;
    margin-left: 9px;
}

.our-activities h1 {
    font-size: 40px;
    font-weight: 700;
    color: #2D67AA;
}

.our-activities .container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100% !important;
}
.our-activities .text-overlay {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
}
.our-activities .text-overlay p {
    font-size: 1.3rem;
    background-color: rgba(45, 103, 170,0.8); 
    border-radius: 10px;
}
@media (max-width: 768px)and (min-width: 530px){
    .our-activities h2 {
        font-size: 2rem;
        font-size: bold;
    }
    .our-activities h1 {
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
}
/* our activities section Ends */

@media screen and (max-width: 768px) {
    .crs-main {
        margin-top: 10px;
    }
}
@media (max-width: 425px){
    .home-csr-img img{
        padding-top: 5rem;
    }
}


