.Inner-oil-section {
    padding: 10rem 0rem 9rem  ;
}

.oilgas-fit {
    max-width: 100%;
    height: auto;
}

.tabel-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table-bordered thead th {
    border-bottom-width: 2px;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table td,
.table th {
    padding: 3px;
    vertical-align: top;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
    margin: 0 auto;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@media (max-width: 468px) {
    .table {
      font-size: 0.5rem; /* Apply small font size for the table on small screens */
      flex-direction: column; /* Stack rows vertically if using Flexbox */
    }
  
    .table-row {
      flex-direction: column; /* Ensure table rows stack vertically */
    }
  
    .table-cell {
      width: 100%; /* Make each cell take full width in stacked view */
    }
  }
 