.order-form-popup-2 {

    position: absolute;
    padding: 2rem !important;
    top: 0 !important;
    transform: translate(0%, 10%);
    left: 0 !important;
    right: 0 !important;
}

.Supply-form b {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4F84B2;
}

.order-note {
    color: brown;
}

.supply-order-form label {
    font-weight: 500;
    color: #4F84B2;
}