.brass-ElectricalA-Section {
    background-image: url("../../../../Assets/Images/Products/BrassElectricalA.png");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.brass-ElectricalA-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.brass-ElectricalA-Section .brassE-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.brassE-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.Elect-Access-Section-2 {
    padding: 30px 0px;
}

@media (max-width:786px) {
    .brass-ElectricalA-Section .brassE-subheader-content {
        padding: 80px 0 16px;
    }

    .brassE-subheader-content h1 {
        font-size: 27px;
        margin-top: 24%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
    }
}