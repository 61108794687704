.image-slider-section {
    margin-left: -5%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.slider h2 {
    font-size: 40px;
    font-weight: 700 !important;
    color: #2D67AA;
    text-align: center;
}

.server-header span {
    color: #3AC4F2;
}


.img-container {
    width: 100%;
    height: auto;
    padding: 8rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    max-width: 1000px;
    z-index: 0;
}

.img-container img {
    width: 400px;
    margin-left: 1%;
    height: auto;
    background-color: #87A0E2;
    border-radius: 16px;
    position: absolute;
    user-select: none;
    transition-property: transform, opacity, zIndex, ;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.arrows {
    width: 800px;
    height: auto;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.arrows svg {
    width: 40px;
    height: 40px;
    position: relative;
    fill: black;
    opacity: 0.8;
    cursor: pointer;
}

.arrows svg:active {
    transform: scale(0.9);
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.our-industries-img-section-none {
    display: none;
}

@media(max-width:2560px){
    .both-navbar{
        width: 100%;
    }
}


@media only screen and (max-width: 1024px) {
    .img-container {
        display: none !important;
    }

    .our-industries-img-section-none {
        display: block;
    }

    .img-container img {
        width: 100%;
    }
}
@media(max-width:1024px){
    .our-industries-img-section-none {
        margin-top:-9rem !important;
    }
}
@media(max-width:425px){
    .our-industries-img-section-none {
        margin-top:-5rem !important;
    }
}
@media (max-width:786px) {
    
    .slider h2 {
        font-size: 31px;
    }

    .image-slider-section {
        background-image: url('../../Assets/Images/Home/our-industries.jpg');
    }
}