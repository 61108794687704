.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2%;
}

.error-imagediv {
  width: 500px;
}

.error-head {
  margin-top: 4%;
}

.error-head h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.error-button {
  margin-top: 1rem;
}

.back-home {
  padding: 0.7rem 2rem 0.7rem 2rem;
  background: #005089;
  color: white;
  border-radius: 2rem;
  font-size: 1.2rem;
  border: none;
  text-decoration: none;
  animation: pulse 5s infinite !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}