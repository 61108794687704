
.carousel {
 z-index: 0 ;
}
.carousel-text img {
  width: 30%;
  height: 30%;
  background: none;
  border: none;
}

.carousel-img {
  object-fit: cover;
}

.carousel-text {
  position: absolute;
  top: 30%;
  left: 2%;
  text-align: left;
  width: 30%;
  opacity: 0;
  line-height: 1rem;
}

.carousel-text h2 {
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 1;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.carousel-text h2 b {
  color: #3AC4F2;
}

.carousel-text h4 {
  font-weight: 500;
  font-size: 1rem;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.carousel-item {
  position: relative;
  margin-top: 8.5rem;
}
#carousel-inner-item{
  margin-top: -8rem;
}

@keyframes leftToRight {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.carousel-item.active .carousel-text {
  animation: leftToRight 0.5s ease-in;
  opacity: 1;
}

.carousel-item .carousel-text {
  transition: opacity 0.5s ease-out;
  /* Add a transition for smoother visibility changes */
}

.carousel-item {
  position: relative;
}

@media (max-width: 1024px) {

  .carousel-text h2 {
    font-weight: 600;
    font-size: 2.5rem;

    color: white;
    font-family: 'Roboto', sans-serif;
  }

  .carousel-text h4 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .carousel-img {
    height: 200px;
  }
}

.wellcomese {
  margin-top: 2%;
  margin-bottom: 4%;
}


.wellcomese h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  color: #2D67AA;
  margin-bottom: 10px;
  margin: 0 0 26px;
}

.wellcomese-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-head {
  margin-left: 1% !important;
  color: #3AC4F2 !important;
}

.WecomesectionTitle p {
  font-size: 17px;
  line-height: 24px;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.right-txt p {
  font-size: 15px;
  line-height: 24px;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.welcome_Img img {
  max-width: 100%;
  height: auto;
  margin-top: 15px;
}


/* R & D Department */
.RandDSection {
  position: relative;
  padding: 50px 0px 30px;
  background-color: #005089;
}

.RandD-Main h1 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.RandD-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.randdleftimg img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.randdrightContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 121px;
}

.randdrightContent h4 {
  color: #fff;
  font-weight: 700;
}

.randd-text {
  color: #fff;
}

.readMoreBut .Read-MoreInner {
  padding: 12px;
  margin-left: 0;
  width: 50%;
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
  font-weight: 600;
  font-size: 19px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* / our product range section start / */
.ProductRangeMain {
  background-color: #0A3166;
}

.ProductRangeMain h1 {
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.serviceer {
  border: 2px solid black;
  width: 100%;
  color: #ffffff;
  background-color: #0a3166 !important;
  height: 100%;
  display: flex;
}

.home-services h2 {
  text-align: center !important;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.home-service-container {
  margin-top: 0;
  padding: 20px 0;
}

.our-product {
  padding-top: 5%;
  cursor: pointer;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  border: 0.1px solid rgb(247, 247, 247);
  transition: background-color 0.5s ease;
}

.our-product .services {
  background-color: rgb(230, 227, 227);
  border-radius: 50%;
  width: 30%;
  padding: 15px;
  margin: 15px auto;
  display: flex;
  align-items: center;
}

.services img {
  background: none;
  border: none;
  animation: iconDown 3s infinite ease-in-out;
}

@keyframes iconDown {

  0%,
  100% {
    transform: translateY(-3px);
  }

  50% {
    transform: translateY(3px);
  }
}

.our-product:hover {
  background-color: #004a98 !important;
}

.our-product h2,
.our-product p {
  text-align: center;
  color: #004a98;
  transition: color 0.3s;
  font-family: 'Roboto', sans-serif;
}

.our-product h2 {
  font-weight: bold;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
}

.our-product p {
  text-align: justify;
  font-family: 'Roboto', sans-serif;
  word-spacing: 0.5px;
  hyphens: auto;
  padding: 0px 15px;
}

.our-product:hover h2,
.our-product:hover p {
  color: white;
}


/* manufacturing Process css */
.manufacturing {
  padding: 30px 0 30px;
}

.manufacturing span {
  font-size: 40px;
  font-weight: 700;
  color: #3AC4F2;
}

.manufacturing video {
  width: 110vw;
  transform: translate(-5%);
 
}

.manufacturing h1 {
  margin-left: 1%;
  font-size: 40px;
  font-weight: 700;
  color: #2D67AA;
}

.our-services {
  background-color: rgb(255, 255, 255);
  padding: 20px;
}

.our-services span {
  color: #3AC4F2;
  margin-left: 9px;
}

.our-services h1 {
  font-size: 40px;
  font-weight: 700;
  color: #2D67AA;
  /* padding-top: 12px; */
}

.our-services .container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100% !important;
}


.box {
  cursor: pointer;
  margin-bottom: 68px;
  position: relative;
  overflow: hidden;
  width: 40%;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #004a98;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.box:hover::before {
  opacity: 0.7;
}

.box img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.our-services .text-overlay {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: max-content;
}

.welcome-to-section-img img {
  border: none;
  border-radius: 10px;
}

.our-services .text-overlay p {
  font-size: 1.3rem
}

.export-section p {
  text-align: left;
  font-size: 17px;
}

/* Who we Are Css */

.senwell-expo-who-we {
  background-color: rgb(243, 246, 255);
  padding: 5px 0px;
  border: none;
}

.who-section h2 {
  color: rgb(10, 48, 102);
  font-weight: bold;
  margin-bottom: 20px;
}

.who-section p {
  
  text-align: left;
  font-size: 17px;
}

.who-section span {
  display: flex;
  text-align: left;
}

.who-section span img {
  margin-bottom: 5px;
  margin-top: 5px;
  height: auto;
  width: 30%;
  max-width: 100%;
}

.who-section h6 {
  color: #2A2F35;
  text-align: left;
}

@media (max-width: 767px) {
  .who-section h2 {
    font-size: 25px;
  }

  .who-section {
    margin-bottom: 20px;
  }
  #who-we-are-image{
    height: auto;
    width: auto;
    margin: 0;
  }
}






@media (max-width: 850px) {
  .carousel-text h2 {
    font-size: 1.4rem;
    font-weight: bold;
  }
}



@media (max-width: 767px) {
  .box {
    flex: 0 0 calc(50% - 20px);
  }

  .box img {
    width: 100%;
  }

  .wellcomese-heading {
    display: flex;
    flex-direction: column;
  }

  .wellcomese h2 {
    font-size: 30px;
    margin-top: 5%;
    font-weight: 500;
    line-height: 10px;
    color: #2D67AA;
    margin-bottom: 10px;
    margin: 0 0 26px;
  }

  .manufacturing h1 {
    margin-left: 12%;
    font-size: 30px;
    font-weight: 700;
    color: #2D67AA;
  }


  .our-Manufactring-img-section-none h1 {
    margin-left: 10%;
    font-size: 40px;
    font-weight: 700;
    color: #2D67AA;
  }

  .our-Manufactring-img-section-none span {
    font-size: 40px;
    font-weight: 700;
    color: #3AC4F2;
  }

  .manufacturing span {
    font-size: 30px;
    font-weight: 700;
    color: #3AC4F2;
  }

  .product-section h1 {
    font-size: 30px;
    font-weight: 700;
    color: #2D67AA;
  }

  .sec-head {
    margin-left: 0% !important;
  }
}

.our-Manufactring-img-section-none {
  display: none !important;
}

@media (max-width: 479px) {
  .box {
    width: 100%;
    flex: 0 0 calc(100% - 20px);
  }
}

@media (max-width: 520px) {
  .carousel-text h2 {
    font-size: 0.7rem;
    font-weight: normal;
  }

  .carousel-text h4 {
    font-weight: normal;
    font-size: 0.4rem;
  }
}
@media(max-width:425px){
  .first-carousel{
    margin-top: 5.7rem;
  }
}

@media (max-width: 530px)and (min-width: 280px) {
  .manufacturing {
    display: none !important;
  }

  .our-Manufactring-img-section-none {
    display: block !important;
    width: 100%;
    height: 100%;

  }

  .img-fluid {
    width: 100%;

  }

  .our-Manufactring-img-section-none h1 {
    margin-left: 7%;
    font-size: 35px;
    font-weight: 700;
    color: #2D67AA;
  }

  .our-Manufactring-img-section-none span {
    font-size: 35px;
    font-weight: 700;
    color: #3AC4F2;
  }
}

@media (max-width: 768px)and (min-width: 530px) {
  .carousel-img {
    height: 200px;
  }

  .manufacturing {
    display: none !important;
    
  }

  .our-Manufactring-img-section-none {
    display: block !important;
    width: 100%;
    height: 100%;
    
  }

  .carousel-text h2 {
    font-size: 1.3rem;
    font-weight: normal;
  }

  .carousel-text h4 {
    font-weight: normal;
    font-size: 0.8rem;
  }

  .carousel-text {
    position: absolute;
    top: 15%;
    left: 2%;
    text-align: left;
    width: 30%;
    opacity: 1;
    line-height: 1rem;
    transition: opacity 1s ease-out;
  }

  .ProductRangeMain {
    background-color: #0A3166;
    height: 100%;
  }

  .right-txt p {
    margin-top: 16px;
  }

  .manufacturing video {
    height: 500px;
    width: 50%;
    object-fit: cover;
  }

  .wellcomese h2 {
    font-size: 2rem;
  }

  .randdrightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 12px;
  }

  .our-services h2 {
    font-size: 2rem;
    font-size: bold;
  }

  .ProductRangeMain h1 {
    font-size: 34px;
    font-weight: 600;
  }

  .manufacturing span {
    font-size: 36px;
  }

  .product-section h1 {
    font-size: 35px;
  }

  .RandD-Main h1 {
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .our-services h1 {
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#who-we-are-image{

   width: 80%;
   height: 80%; 
   border-radius: 10px; 
   margin: auto;
}

.our-Manufactring-img-section-none .img-fluid{
  margin-top: 0rem !important;
  justify-items: center;
}
  
.carousel-control-prev-icon,.carousel-control-next-icon{
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
