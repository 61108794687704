.Oil-Section {
    background-image: url("../../../Assets/Images/Products//Oil-Gas.png");
    background-size: 100% 100%;
    width: 100%;
    height: 500px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.Oil-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.Oil-Section .Oil-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.Oil-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.oil-gas-section {
    padding: 33px 0;
}

.oil-header h3 {
    font-weight: 600;
    font-size: 36px;
    color: #062f56;
    margin-bottom: 20px;
}

.oil-ul {
    margin-bottom: 15px;
}

ul>li {
    list-style-type: none;
    margin-bottom: 5px;
}

.oilandgastext {
    font-size: 15px;
    color: #14212b;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
}

/* Container for each image */
.oil-gas-image-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oil-gas-product-image {
    /* height: 270px; */
    width: 100%;
    display: block;
    transition: filter 0.8s ease;
}

.oil-gas-overlay-text {
    position: absolute;
    bottom: -100%;
    left: 16px;
    right: 0;
    background-color: rgba(0, 140, 194, 0.85);
    color: white !important;
    text-align: center;
    opacity: 0;
    height: 40%;
    width: 92%;
    transition: bottom 0.8s ease, opacity 0.8s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
}

.oil-gas-overlay-inner-text span {
    color: #fff !important;
    font-weight: bold;
    font-size: 15px;
}

.oil-gas-image-container:hover .oil-gas-product-image {
    filter: brightness(0.7);
}

.oil-gas-image-container:hover .oil-gas-overlay-text {
    bottom: 0;
    opacity: 1;
}

@media screen and (min-width:425px){
    .oilgas-video{
        padding-top:8rem !important ;
    }
}