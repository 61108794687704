.Inner-fastener-Section {
    background-image: url("../../../../../Assets//Images/Products//Brass-nut.webp");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.Inner-fastener-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.Inner-fastener-Section .Inner-fastener-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.Inner-fastener-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.Inn-fas-section2 {
    padding: 50px 0;
}

.inner-fast-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.animate-img {
    position: relative;
    overflow: hidden;
}

.PCard {
    background-color: #2460a6 !important;
    text-align: center;
    font-weight: bold;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-body h4 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    margin-bottom: 0.75rem;
}

.seview {
    color: #14212b;
    text-decoration: none;
    transition: 0.5s;
}

.seview button {
    border: none;
    cursor: pointer;
    background-color: lemonchiffon;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
}