.Fastener-Section {
    background-image: url("../../../../Assets//Images//Products/brass-fasteners.webp");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.Fastener-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.Fastener-Section .fastener-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.fastener-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.fastener-product-section {
    padding: 20px 0px;
}

.product h3 {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .Fastener-Section .fastener-subheader-content {
        padding: 90px 0px 160px;
    }

    .fastener-subheader-content h1 {
        font-size: 39px;
        margin-top: 19%;
        margin-bottom: 5px;
        color: #fff;
        font-weight: 600;
    }

    .Inner-fastener-subheader-content h1 {
        font-size: 50px;
        color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Inner-fastener-Section .Inner-fastener-subheader-content {
        padding: 140px 0px !important;
    }
}