.Aerospace-section {
	background-image: url('../../../Assets/Images/Products//AeroBanner.png');
	background-size: 100% 100%;
	width: 100%;
	height: 400px;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.Aerospace-section::before {
	content: '';
	background-color: #14212b;
	opacity: 0.5;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: block;
}

.Aerospace-section .subheader-content {
	position: relative;
	padding: 205px 0 160px;
}

.subheader-content h1 {
	font-size: 62px;
	margin-top: 3%;
	margin-bottom: 5px;
	color: #fff;
	font-weight: 600;
}

.Aerospace-section-2 {
	padding: 20px 20px;
}

.aerospace-product-head h3 {
	font-weight: 700;
	font-size: 36px;
	color: #062f56;
}

.inner-box {
	background-color: #fff;
	box-shadow: 0px 1px 10px 0px #00000017;
}

.animation-img {
	position: relative;
	overflow: hidden;
	transition: transform 0.5s;
}

.animation-img:hover {
	transform: scale(0.9);
}

.img-fit {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.inner-box .service-caption {
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 10px;
}

.arr-button {
	display: inline-flex;
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: #f3f5f7;
	font-size: 16px;
}

.left-side h4 {
	font-size: 18px;
	margin: 0;
	color: #062f56;
}

.arr-button {
	text-decoration: none;
	cursor: pointer;
}

.arr-button i {
	text-decoration: none;
	color: #062f56;
}

.arr-button:hover {
	background-color: #2460a6;
	color: #fff;
}

/* Video */
.video-container {
	position: relative;
	width: 100%;
	padding-top: -0.75%;
	pointer-events: none !important;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

/* New css */
.image-container {
	position: relative;
	overflow: hidden;
	width: 100%;
}

/* Product Image Style */
.product-image {
    /* height: 270px; */
    width: 100%;
    display: block;
    transition: 0.5s ease;
}

.overlay-text {
	position: absolute;
	bottom: -50%;
	left: 0;
	right: 0;
	background-color: rgba(0, 140, 194, 0.85);
	color: white;
	text-align: center;
	opacity: 0;
	height: 40%;
	width: 95%;
	transition: bottom 0.5s ease, opacity 0.5s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	box-sizing: border-box;
}

.overlay-inner-text span {
	font-weight: bold;
	font-size: 15px;
	color: #fff;
}

/* On Hover */
.image-container:hover .product-image {
	filter: brightness(0.7);
}

.image-container:hover .overlay-text {
	bottom: 0;
	opacity: 1;
	width: 100%;
}

.q-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Responsive Design for smaller screens */

@media (min-width: 370px) {
	.product-image {
		
        /* width: 23rem; */
        display: block;
        transition: 0.5s ease;
        max-height: 23rem;
        object-fit: fill;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		 /* width: 100%; */
		
	}
}

@media (min-width: 570px) {
	.product-image {
        display: block;
        transition: 0.5s ease;
        height: 11rem;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		/* width: 11rem; */
	}
}


/* Responsive Design for smaller screens */
@media (min-width: 800px) {
	.product-image {
		height: max-content;
		/* width: 10rem; */
		display: block;
		transition: 0.5s ease;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		/* width: 10rem; */
	}
}

/* Responsive Design for smaller screens */
@media (min-width: 900px) {
	.product-image {
		height: max-content;
		/* width: 11rem; */
		display: block;
		transition: 0.5s ease;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		/* width: 11rem; */
	}
}

@media (min-width: 1200px) {
	.product-image {
		height: max-content;
		/* width: 14rem; */
		display: block;
		transition: 0.5s ease;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		/* width: 14rem; */
	}
}

/* Responsive Design for smaller screens */
@media (min-width: 1400px) {
	.product-image {
		height: max-content;
		/* width: 19rem; */
		display: block;
		transition: 0.5s ease;
	}

	.image-container {
		position: relative;
		overflow: hidden;
		/* width: 19rem; */
	}
}
