.image-blog-header-blog9 {
    width: 100%;
    height: 550px;
    margin-top: -50px;
    background-image: url('../../../Assets/Images/blog/6.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 300px)and (max-width:600px) {
    .image-blog-header-blog9 {
        width: 100%;
        height: 150px;
    }
}