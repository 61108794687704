/* mobile view section :start */

nav.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  height:5.4rem;
}

#navbarSupportedContent {
  background-color: white !important;
  margin-right: -33px !important;
  margin-left: -28px !important;
}


.mobile-icon-section {
  display: none;
}

.mobile-icon-section .mobile-no {
  display: flex;
  margin-left: 35%;
}

.mobile-no-social-icons .social-icon {
  cursor: pointer;
}

.mobile-no-social-icons {
  display: flex;
}

.both-navbar{
  position: fixed;
  width: 100vw;
  z-index: 1;

}

.first-navbar {
  display: block;
}

.lang input {
  width: 80%;
}

#language option {
  font-size: 0.8rem;
  
}

/* mobile view section :End */
.first-navbar .header {
  display: block;
}

.first-navbar .header-area .header-menu.rt-sticky {
  background: #2d276c;
}


.first-navbar .header-top-bar {
  background-color: #0A3166;
  min-height: 34px;
  border-bottom: none;
}

.first-navbar .header-top-bar .Header-Certified {
  /* margin-right: 25px; */
  position: relative;
  padding-right: 25px !important;
}

.first-navbar .header-top-bar .Header-Certified::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #fefefe;
  transform: skew(-20deg);
}

.first-navbar .header-top-bar .header-item {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  padding: 13px 0;
  display: inline-block;
  margin-right: 13px;
}


@media screen and (max-width: 1400px) {
  #inside-container {
    
    gap: 2rem !important;
    justify-content: space-between!important;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 15rem !important;
  }

  .top-right {
    width: auto !important;
    flex-shrink: 0;
    padding-right: 5rem !important; 
    margin-right: 2rem !important;
  }

  .topbar-left {
    width: auto;
    margin: 0;
    text-align: left;
    flex-shrink: 0;
  }

  .header-phone {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .Header-Certified {
    padding: 0 !important;
    white-space: nowrap;
  }
  .both-navbar{
    width: 100vw;
  }
}



.first-navbar .header-top-bar .header-item i {
  margin-right: 18px;
  color: #fff;
  font-size: 18px;
  width: 18px;
  vertical-align: middle;
  line-height: 1;
  display: inline-flex;
  align-items: center;
}

.first-navbar .header-top-bar .header-item a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.first-navbar .top-right {
  display: flex;
}

.first-navbar .header-top-bar .top-right .header-phone {
  font-weight: 500;
}

.first-navbar .top-right .header-phone {
  font-weight: 400;
  /* margin-right: 25px; */
  position: relative;
  padding-right: 25px;
}

.first-navbar .top-right .header-phone::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #fefefe;
  transform: skew(-20deg);
}

.first-navbar .top-right .header-phone span {
  font-weight: 400;
  margin-right: 3px;
}

.first-navbar .top-right ul .topbar-social {
  display: inline-block;
  padding: 10px;
}

.header-top-bar ul {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;

}

.first-navbar .header-top-bar .top-right ul .topbar-social a {
  font-size: 18px;
  color: #fff;
}

.first-navbar .top-right ul .topbar-social a {
  margin-right: 10px;
}

.first-navbar .se-site-header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem !important;
  font-family: 'Archivo Black', sans-serif !important;
}

.logo img {
  border: none;
  width: 90px !important;
  height: 90px !important;
  background: none;
  border-radius: 50%;
}

.outer-logo {
  position: relative !important;
}

.logo .inner-static-logo-container img {

  border: none;
  width: 47px !important;
  height: 16px !important;
  background: none;
}


.inner-static-logo {
  height: 5px !important;
  position: absolute !important;
  top: 43% !important;
  left: 3.5% !important;
}

@media (max-width:281px) {
  .logo img {
    border: none;
    object-fit: cover;
    background: none;
    border-radius: 50%;
  }
  .both-navbar{
    width: 100vw;
  }
  .navbar-brand {
    font-size: 5px;
  }
}
.navbar-nav{
  margin-left: 0px;
  background-color: #ffffff;
}
.navbar-nav .nav-item.active a.nav-link {
  color: #04264d;
}
.nav-item {
  padding-left: 17px;
}
.navbar {
  background-color: #ffffff;
  width: 100%;
}

/* Menu css */
.dropdown-menu {
  display: none;
  border: none !important;
  background: #fff;
  box-shadow: 0 0 90px 0 rgba(0, 0, 0, 0.1) !important;
  padding: 15px 0px;
  
}

.dropdown-menu .nav-item a {
  overflow: hidden;
  display: block;
  color: rgba(33, 37, 41, 0.75);
  text-transform: inherit;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  text-decoration: none;
  
}

.dropdown-menu .nav-item1 a {
  overflow: hidden;
  display: block;
  color: rgba(33, 37, 41, 0.75);
  text-transform: inherit;
  padding: 7px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  text-decoration: none;
}

.dropdown-menu .nav-item1 a {
  transition: color 0.6s ease-in-out, transform 0.6s ease-in-out !important;
}

.dropdown-menu .nav-item1 a:hover {
  color: #2d67aa;
  transform: translateX(10%);
}



.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .sub-dropdown-menu {
  display: none;
}

.nav-item a:hover {
  color: #2d67aa;
}

.dropdown-menu .nav-item a {
  transition: color 0.6s ease-in-out,
    transform 0.6s ease-in-out;
}


.dropdown-menu .nav-item a:hover {
  color: #2d67aa;
  transform: translateX(10%);
}

.dropdown-menu .dropdown:hover .sub-dropdown-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu-second {
  left: 160px;
  top: 0;
  width: 250px;
  position: absolute;
  background-color: #fff;
  text-align: left;
  display: none;
}

.dropdown-menu-second li {
  font-size: 14px;
  margin-left: -2rem;
  line-height: 1rem;
  list-style-type: none !important;
}

.dropdown-menu-second li a {
  text-align: left !important;
  left: 0;

}

.electricDropdown:hover .dropdown-menu-second {
  display: block !important;
}

.dropdown-menu-second li a {
  cursor: pointer;
}


.logo a {
  color: #2d67aa;
  font-family: 'Jost', sans-serif;
  font-size: 25px;
}

.contact-no p {
  line-height: 0.01rem;
}

.rotating-logo {
  animation: rotate 5s infinite linear;
}


@media (max-width: 1024px) {
  .logo a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem !important;
    font-family: 'Archivo Black', sans-serif !important;
  }
}



@media (max-width: 767px) {
  .dropdown-menu-second li {
    font-size: 14px;
    margin-left: -1rem;
  }

  .dropdown-menu-second {
    margin-left: 8rem;
    width: 60%;
  }

  .electricDropdown a,
  .aerospace a {
    margin-left: 2%;
  }

  .mobile-icon-section {
    display: block;
  }

  .first-navbar {
    display: none;
  }

  .mobile-icon-section {
    width: 100%;
  }

  .mobile-icon-section .mobile-no {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    margin-left: 0%;
  }

  @media (max-width:425px){
    .logo{
      margin-left: -1.3rem;
    }
    .navbar-brand{
      margin-right: 2px; 
    }
    .navbar{
      padding-top: -3rem;
      padding-bottom: -3rem;
      height: 3rem !important;
      margin-top: -0.2rem;
      margin-bottom: -20px;
    }
    .container-fluid{
      margin-top: -1.1rem;
    }
    .navbar-toggler{
      padding: 1px;
    }
    
    
  }
  #language {
    width: 100%;
  }

  .logo a {
    font-size: 1rem !important;
    font-family: 'Archivo Black', sans-serif !important;
  }

  /* logo changes */
  .logo img {
    border: none;
    width: 50px !important;
    height: 48px !important;
    background: none;
  }

  .dropdown-menu-second {
    left: 0;
    top: 0;
    width: 250px;
    position: absolute;
    background-color: #fff;
    text-align: left;
    display: none;
    overflow: hidden;
  }
}
#header-parent-div{
  background-color: #ffffff;
}
.navbar-collapse{
  width: 70vw;
  height: 5vh;
}
.navbar-nav{
  justify-content: center;
}

@media (max-width: 1252px) {
  .nav-item{
    font-size:8%;
  }
  .nav-item{
    font-size:100%;
  }
}
@media (min-width: 1200px) {
  #header-parent-div {
    display: none;
  }
}

@media (max-width: 1200px) {
  
  .both-navbar{
    width: 100vw;
  }
  #header-parent-div {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
  }

  .dropdown-menu-second li {
    font-size: 14px;
    margin-left: -1rem;
  }

  .dropdown-menu-second {
    margin-left: 8rem;
    width: 60%;
  }

  .electricDropdown a,
  .aerospace a {
    margin-left: 2%;
  }

  .mobile-icon-section {
    display: block;
  }

  .first-navbar {
    display: none;
  }

  .mobile-icon-section {
    width: 100%;
  }

  .mobile-icon-section .mobile-no {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    margin-left: 0%;
  }

  #language {
    width: 100%;
  }

  .logo a {
    font-size: 1rem !important;
    font-family: 'Archivo Black', sans-serif !important;
  }

  /* logo changes */
  .logo img {
    border: none;
    width: 50px !important;
    height: 48px !important;
    background: none;
  }

  .dropdown-menu-second {
    left: 0;
    top: 0;
    width: 250px;
    position: absolute;
    background-color: #fff;
    text-align: left;
    display: none;
    overflow: hidden;
  }
}
.navbar-nav .nav-link{
  font-size: 100% !important;
}

.child-li {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .child-li {
    padding: 10px;
  }
}

@media(max-width:1230px){
  .navbar-brand{
    margin-right: 200px;
  } 
}

@media (max-width:1445px) {
  .navbar-nav .nav-item{
    padding-left: 0px !important;
  }
  .navbar-nav .nav-link{
    font-size: 100% !important;
  }
}
@media (max-width:1295px) and (min-width:1201px) {
  .navbar-nav .nav-link{
    font-size: 86% !important;
    /* font-weight:bold !important; */
  }
}
@media(max-width:1234px){
  .social-icon{
    width: 100%;
    margin-right: 20rem !important;
  }
  .header-phone {
    padding-right: 0.5rem !important;
  } 
}
@media (max-width:1360px) and (min-width:1200px ){
  .logo img{
    width: 7.5rem !important;
    height: 90px !important;
  }
}