.Infrastructure-section-home {
    position: relative;
    width: 100%;
    
}

.infrastructure-img {
    position: relative;
    width: 100vw;
    padding-top: 6rem ;
}

.infrastructure-img img {
    width: 100vw;
    height: auto;
    
}


.text-Infra {
    position: absolute;
    top: 90%;
    left: 40%;
    transform: translateY(-50%);
    color: white;
    text-align: center;
    font-size: 3rem;
}
.table-striped {
    width: 100% !important;
    
    box-sizing: border-box;
}

.machine-table {
   
    padding: 0px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}


.table-striped thead th {
    background-color: #3891a7;
}
.info-container{
    margin-top: -3rem !important;
}
.info-container .img-fluid{
    margin-top: 8.5rem !important;
}
.text-Infra h1 {
    font-size: 3rem;
    margin: 0;
}

.export-tr td {
    text-align: center;
}

.export-Sr font {
    text-align: center;
}

.SEsr {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.sepage-header h1 {
    margin-top: 30px;
}


.Unit-Sections {
    padding: 0.5rem 0rem ;
    margin-top:-2rem ;
    background-color: rgb(243, 246, 255);
}

.Unit-Sections h1 {
    font-size: 40px !important;
    font-weight: 600;
    margin-top: 10px !important;
    font-size: 30px;
    margin: -4px 0 18px;
}

.Unit-Sections p {
    margin-bottom: 0px;
}

/* New css */
.export-img {
    position: relative;
    min-height: 1px;
    padding: 15px;
}

.Machine-image {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

.machine-image-container{
    justify-content: center;
    align-items: center;
}

.export-img:nth-child(12) {
    height: 20rem !important;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    & img {
        height: 100% !important; 
        width: auto;
        max-width: 100%;
        object-fit: contain;
        padding-bottom: 0 !important;
        transform: translateY(-5%);
    }
}

.export-img img {
    max-height: 20rem;
    width: auto;
    max-width: 100%;
    object-fit: contain;
}

@media(max-width:1200px){
    .infrastructure-img img{
        padding-top: 0.7rem ;
    }
}

@media(max-width:768px)and (min-width:587px){
    .Infrastructure-section-home{
        padding-top: 8% !important;
    }
}
@media(max-width:587px){
    .Infrastructure-section-home{
        padding-top: 13% !important;
    }
    .infrastructure-img img{
        margin-top: -9rem !important;
        padding-top: 0rem;
        
    }
}
@media(max-width:485px){
    .Infrastructure-section-home{
        padding-top: 5rem !important;
    }
    .Unit-Sections .heading-text{
        font-size: 1.3rem !important;
        
    }
    
}
@media(max-width:422px){
    .infrastructure-img{
        margin-top: -2rem !important;
    }
}
@media (max-width:426px){
    .infrastructure-img{
        margin-top: -1rem ;
    }
    .text-Infra{
        font-size: xx-large;
        left: 10% !important;
    }
}
@media (max-width:425px){
    .Infrastructure-section-home{
        padding-top: 19% !important;
    }
    
    .infrastructure-img{
        padding-top: 4rem !important;
    }
    
}
@media(max-width:388px){
    .infrastructure-img{
        margin-top:-0.5rem !important;
    }
    .text-Infra{
        font-size: large;
        /* transform: translate(100% ,100%); */
    }
}

@media (max-width:1199px) and (min-width:992px){
    .export-img:nth-child(1) p{
       padding: 0.6rem;
    }
    .export-img:nth-child(3) p{
       padding: 0.6rem;
    }
    
}

.exe-text {
    position: absolute;
    z-index: 0;
    bottom: 0;
    background-color: rgba(45, 103, 170, 0.7);
    color: white;
    width: calc(100% - 30px);
    padding: 10px 9px 10px 29px;
    border-radius: 0px 0px 7px 7px;
    margin-top: 0;
    margin-left: 0;
    text-align: center;
    font-weight: 700;
    max-height: min-content;
}

.exe-text:hover {
    background-color: rgb(44, 69, 78);
}

.hover-info {
    display: none;
    position: absolute;
    top: 4;
    bottom: 150px;
    left: 62%;
    transform: translateX(-10%);
    background-color: rgba(45, 103, 170, 0.7);
    color: #ffff;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    transition: opacity 0.3s ease;
    font-size: small;
    font-weight: 500;
    width: 35%;
}

.hover-info ol li span {
    font-weight: 700;
}

.export-img:hover .hover-info {
    display: block;
}

.custom-dot-list {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 0;
    margin-left: 0;
}

.custom-dot-list li {
    margin-bottom: 5px;
    padding-left: 0;
    white-space: nowrap;
}

/* coordinate css  */
.hover-info-coordinate {
    display: none;
    position: absolute;
    top: 4;
    bottom: 18px;
    left: 62%;
    transform: translateX(-10%);
    background-color: rgba(45, 103, 170, 0.7);
    color: #ffff;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    transition: opacity 0.3s ease;
    font-size: small;
    font-weight: 500;
    width: 35%;
}

.hover-info-coordinate ol li span {
    font-weight: 700;
}

.export-img:hover .hover-info-coordinate {
    display: block;
}

.custom-dot-list {
    list-style-type: disc;
    list-style-position: inside;
    padding-left: 6px;
    margin-left: 0;
}

.custom-dot-list li {
    margin-bottom: 5px;
    padding-left: 0;
    white-space: nowrap;
}

.custom-dot-list{
    font-size: 0.5rem;
}
.hover-info-coordinate{
    width:auto;
}

@media (min-width:768px) {
    .infrastructure-img img {
        margin-top: 2.7rem;
        /* height: 400px; */
        /* Larger height for bigger screens */
    }
    .custom-dot-list{
        font-size: 1rem;
    }
    .hover-info-coordinate{
        width:auto;
    }
}
@media(max-width:768px){
    .infrastructure-img img{
        margin-top: -2.3rem !important;
    }
    .table-striped td{
        width: 100vw;
        font-size: larger;
    }
    .table-striped {
      padding: 0rem 1rem;
    }
    .text-Infra{
        left: 32% !important;
    }
}

@media (max-width:1400px) and (min-width:768px){
    .export-img:nth-child(10){
        & img{
            transform: translateY(15%);
        }
    }
    .export-img:nth-child(11){
        & img{
            transform: translateY(15%);
        }
    }
}
@media (max-width:1300px){
    .text-Infra{
        left: 39% ;
    }
}
