.innerBlogBody {
    background: white;
}

.innerBlogBody .blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background: white;
}

.blog-container-date {
    text-align: center;
    color: #8B8B8B;
    /* margin: 10px 0 25px 0px; */
}

.blogMain-header {
    font-size: 40px;
    text-align: center;
    letter-spacing: 0px;
    color: #E84625;
    opacity: 1;
    line-height: 4rem;
    font-weight: bold;
    margin: 10px 0;
}

.image-blog-header-blog1 {
    width: 100%;
    height: 400px;
    margin-top: -50px;
    background-image: url("../../../Assets/Images/blog/Cultural.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-blog-header img {
    object-fit: cover;
    border-radius: 0px 0px 60px 60px;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.image-blog-header>h1 {
    color: black !important;
}

.content-blog {
    padding: "50px 0px"
}

.entry-title {
    font-size: 2.25rem;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    font-family: "Raleway", Arial, Helvetica, sans-serif;
    text-align: start;
    letter-spacing: 0;
    /* margin: 40px 0 20px 0px; */
}

.entry-title h1,
.entry-title p {
    color: white;
    text-align: center;
}

/* New Css */
.javaAppDevelopment {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    overflow-wrap: break-word;
    font-size: 1.0625rem;
    
    font-family: "Ubuntu", Arial, Helvetica, sans-serif;
    text-align: justify;
    line-height: 1.5;
}

.link-blog {
    color: #22aadd;
    text-decoration: underline;
}

.javaAppDevelopment2 {
    margin-bottom: 15px;
    font-size: 1.0625rem;
    font-family: "Ubuntu", Arial, Helvetica, sans-serif;
    text-align: justify;
}

.entry-sub-title {
    margin-top: 1.5em;
    margin-bottom: 1em;
    overflow-wrap: break-word;
    font-size: 1.7rem;
    text-align: start;
}

.entry-sub-title-2 {
    margin-top: 1.5em;
    /* margin-bottom: 1.5em; */
    overflow-wrap: break-word;
    font-size: 1.4rem;
    text-align: start;
}

@media screen and (max-width: 768px) {

    .entry-title h1,
    .entry-title p {
        font-size: 1.5rem;
        padding: 10px;
        line-height: 25px;
    }
}

@media(max-width:488px){
    .entry-title .blogMain-header{
        font-size:1.2rem !important;
    }
}

@media(max-width:405px){
    .entry-title .blogMain-header{
        font-size:1.1rem !important;
        width: 100%;
    }
}
@media(max-width:423px){
    .image-blog-header-blogCertificate {
        padding-top: 0.7rem !important;
    }
}