.blog-section {
    background-image: url("../../Assets/Images/blog/BlogHome.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100% !important;
    height: auto;
}

.Exportcard {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
}

.blogbox {
    border-radius: 8px;
    height: 100%;
}

.blogbox:hover {
    box-shadow: 0px 0px 23px #00000047;
    border-color: transparent;
}

.blog-ig {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 6px;
}

.card-text {
    margin-bottom: 1rem;
    letter-spacing: 0.2em;
    font-size: 12px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem !important;
    z-index: 0 !important;
}

.sub-header {
    position: relative;
    padding: 205px 0 160px;
}

.sub-header h1 {
    font-size: 62px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
}

.card-title {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
    letter-spacing: .02em;
    font-weight: 500;
    line-height: 1.2;
}

.blogLinks {
    text-decoration: none;
}

@media screen and (max-width: 450px) {
    .blog-section {
        background-size: cover;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .sub-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 205px 0 160px;
    }

    .sub-header h1 {
        font-size: 50px;
        margin-top: 0%;
        margin-bottom: 5px;
        color: #fff;
    }
}