.scroll-to-top {
  position: "fixed";
  bottom: "20px";
  right: "10px";
  cursor: pointer;
  z-index: 9999999;
}

.scrollbutton {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1.25rem;
  width: 2.75rem;
  height: 2.75rem;
  /* transition: bottom .4s cubic-bezier(.68,-.55,.265,1.55),opacity .3s,background-color .25s ease-in-out; */
  border-radius: 50%;
  background-color: rgba(19, 16, 34, 0.25);
  color: #fff;
  text-decoration: none;
  opacity: 0;
  z-index: 1030;
  bottom: 1.25rem;
  opacity: 1;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.bounce {
  animation-name: bounce;
}
.animated {
  /* animation-duration: 2.5s; */
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.button {
  color: #fff;
}

/*banner*/

.showcase {
  width: 100%;
  height: 700px;
  position: relative;
  color: white;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .showcase {
    width: 100%;
    height: 550px;
  }
}
.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.showcase .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(31, 59, 95, 0.404);
  position: absolute;
  top: 0;
  left: 0;

  z-index: 99999;
}

.showcase h2 {
  margin-top: 170px;
  font-size: 3em;
}

.showcase p {
  margin-top: 8px;
  font-size: 1.2em;
}
.slick-prev:before,
.slick-next:before {
  font-size: 2rem;
}
@media screen and (max-width: 800px) {
  .slick-prev:before,
  .slick-next:before {
    /* font-size: 2rem; */
    display: none !important;
  }
}
