.Precision-blog {
    background-color: white;
}

.Precision-blog-header {
    width: 100%;
    height: 500px;
    margin-top: 0px;
    background-image: url('../../../Assets/Images/blog/MachiningBanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}