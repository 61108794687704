.quality-first-section .image-text {
    position: absolute;
    top: 50%;
    left: 10%;
    color: #fff;
    font-size: 24px;
    text-align: center;
}


.qualityPolicyHeading h1 {
    color: #0A3166;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.qualityPolicyHeading h1 span {
    color: #3Ac4f2;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.quality-second-section-img img {
    border: none;
    border-radius: 10px;
}


.quality-first-section .first-bg-img {
    position: relative;
    & .img-fluid{
        width: 100%;
    }
}

.img-thumbnail {
    border-radius: 15px !important;
}

.quality-section-para,
.quality-section-heading {
    margin-left: 0%;
}

.quality-section-heading h1 {
    font-size: 40px;
    color: #2d67aa;
}

.quality-section-heading2 h1 {
    color: #0A3166;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.quality-section-heading2 h1 span {
    color: #3Ac4f2;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.quality-section-para p {
    text-align: justify;
    color: black;
}

.quality-section-para2 {
    width: 100%;
}

.quality-section-para2 p {
    text-align: justify;
    color: black;
}

.quality-third-section .quality-card-section {
    position: relative;
    z-index: 0;
}

.quality-card-section {
    margin-top: 15%;
}

.quality-icon {
    background-color: #0A3166;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -70px;
    left: 10px;
    z-index: 5;
}

.quality-icon img {
    background: none;
    border: none;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.truck,
.hand {
    width: 80px !important;
}

.thumb {
    margin-top: -0.1rem;
}

.hand {
    width: 85px !important;
    height: 85px !important;
}

.quality-icons-head {
    margin-top: -10%;
}

.quality-card-section h4 {
    margin-top: -12%;
    margin-left: 15%;
    color: #2d67aa;
    font-weight: 700;
    margin-bottom: 10%;
}

.quality-card-section {
    position: relative;
    z-index: 10;
}

.quality-third-section .quality-card p {
    align-items: justify;
    color: #004A98;
    font-weight: 500;
}

.quality-third-section .quality-card {
    position: relative;
    background-color: #F4F1F1 !important;
    margin-left: 7%;
    padding: 20px;
    z-index: 1;
    height: auto;
}

.quality-fourth-section {
    background-color: #0A3166;
    height: 50vh;
}

.quality-fourth-section {
    height: 50vh;
    color: white;
}

.fourth-section {
    text-align: center;
}

.quality-slider {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auto_slider_container {
    width: 100%;
    display: flex;
    margin: auto;
    overflow: hidden;
}

.auto_slider {
    padding: 0;
    margin: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotation 30s linear infinite;
}

.auto_slider img {
    width: 50%;

    background: transparent;
    border: none;
}

.auto_slider li {
    text-align: center;
}

/* New Css */

.intro-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 1em;
}

.certification-heading {
    /* font-size: 29px; */
    font-size:2rem;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #0A3166;
}


/* Style the list */
.certification-list {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

/* Style each list item with custom bullet */
.certification-list li {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.4;
}

.certification-list li::before {
    content: "\2022";
    font-size: 25px;
    color: #333;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.certificate-img {
    width: 83%;
    height: auto;
}

.img-complex {
    width: 100%;
    height: auto;
    margin-top: 25px;
}



@media (max-width: 768px) {
    .certificate-img {
        margin-left: 30px !important;
        margin-right: auto;
        display: block;
    }
}

@keyframes rotation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 768px) {
    .quality-section-para2 {
        width: 100%;
    }

    .quality-section-para,
    .quality-section-heading {
        margin-left: 0%;
    }
    
}

@media (max-width: 768px) {
    .sepolicy {
        margin-top: 35px;
    }

    .quality-icon {
        height: 70px;
        width: 70px;
    }

    .section-heading h3 {
        font-size: 27px !important;
    }

    .quality-slider img {
        width: 100%;
    }

    .quality-slider-container {
        width: 100%;
    }


    .quality-card-section {
        margin: 15% 0%;
    }

    .quality-icons-head {
        margin-top: 0;
    }

    .quality-card-section h4 {
        margin-top: 0;
        margin-left: 0;
    }

    .quality-third-section .quality-card {
        height: auto;
    }

    .quality-section-heading2 h1 {
        font-size: 30px;
    }

    .quality-section-heading2 h1 span {
        font-size: 30px;
    }

    .qualityPolicyHeading h1 {
        font-size: 30px;
    }

    .qualityPolicyHeading h1 span {
        font-size: 30px;
    }

}

@media (min-width: 768px) and (max-width: 1000px) {
    .quality-icon {
        height: 80px;
        width: 80px;
    }
}

.slick-dots {
    position: unset !important; /* Overrides the default position */
  }

  /* Apply styles to slick-dots within the specific slider */
.quality-slider-section .slick-dots {
    position: unset !important;
  }
  
@media(max-width:500px){
    .quality-first-section .image-text{
        margin-top: 3rem !important;
    }
}
