.news-heading {
    margin-top: 5%;
}

.img-source p {
    float: right;
}

.news-img img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

@media (max-width: 768px) {
    .news-img img {
        width: 100%;
        /* Keep the image width at 100% for small devices */
    }
}