.section-header {
    margin: 0 auto;
    padding-bottom: 30px;
    position: relative;
    z-index: 1;
    text-align: center;
}

.section-heading h3 {
    font-size: 36px;
    font-weight: 600;
    color: #2460a6;

}

.Facility-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Facility-header p {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    color: #062f56;
}

.row-slider-img {
    display: flex;
    justify-content: space-around;
    margin: 10px auto;
}

