.Career-section-home {
    position: relative;
    
}

.Career-section-home img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.Career-overlay {
    position: absolute;
    top: 50%;
    left: 9%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.seAnnouncement h1 {
    color: #0A3166;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.seAnnouncement h1 span {
    color: #3Ac4f2;
    font-size: 37px;
    font-weight: 700;
    padding-bottom: 10px;
}

.job-Announcment-section {
    padding: 40px 20px;
}

.seAnnouncement p {
    margin-top: 20px;
    word-wrap: break-word;
    font-size: 18px;
}

.SeOpeningPosition {
    position: relative;
    background-image: url("../../Assets//Images/Career/OpeningBanner.png");
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
}

.seOpen h2 {
    position: absolute;
    margin-top: 5rem;
    color: white;
    font-weight: 600;
    margin-left: 8.5%;
    font-size: 36px;
    
}

.DeskHeading {
    font-weight: 700;
    color: #0a3165;
    font-size: 36px;
    line-height: 44px;
}

.DeskHeading span {
    color: rgb(39 208 255);
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
}

.SeApplyNow {
    padding: 50px 50px !important;
    background-color: rgba(244, 241, 241, 1);
}
.seApplyb:hover{
content: "hello";
background-color: rgb(0, 127, 72);
box-shadow: 0 4px 8px rgba(0, 0, 0.15, 0.5);
}


.job-card {
    border-radius: 10px;
    background-color: #fff;
    width: 100% !important;
    height: 200% !important;
    border: none;
}

.Job-innerContent {
    display: flex;
}

.seApplycol {
    display: flex;
    align-items: center;
    justify-content: center;
}

.seAccordianB {
    margin-bottom: 30px;
}

.seApplyb {
    padding: 13px 13px;
    color: #fff;
    background-color: rgb(61 112 198);
    font-weight: 500;
    overflow: hidden;
    font-size: 18px;
    outline: none;
    border: none;
    border-radius: 10px;
}
.accordion-button{
    z-index: 0 !important;
}
.SedevotedP {
    margin-bottom: 30px;
    font-size: 18px;
}

.DeskVideoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.BDesk-sections {
    padding: 50px 50px;
    background-color: #fff;
}

@media only screen and (max-width: 1024px) {

    /* .DeskHeading {
        margin-left: 25px !important;
    } */

}


/* .DeskHeading {
    margin-left: 65px;
} */

.DeskVideoContainer h2 {
    width: 88%;
    color: #2460a6;
    margin-top: 0.2rem;
    font-size: 20px;
}

.DeskVideoContainer .col-md-6 {
    transition: transform 0.3s ease;
}

.DeskVideoContainer .col-md-6:hover {
    transition: transform 0.3s ease;
    transform: scale(1.05) translateY(-10px);
}

.DeskVideoContainer iframe {
    border-radius: 2%;
}

.LifeAt-Sections {
    padding: 30px 30px;
    background-color: rgba(244, 241, 241, 1);
}

.lifeAt-Section h1 {
    color: black
}

.LifeAt-row h1 {
    font-weight: 700;
    color: #0a3165;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 0rem;
}

.LifeAt-row h1 span {
    color: rgb(39 208 255);
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
}


/* ---------------------- */

.JointousToday {
    position: relative;
    background-image: url("../../Assets/Images/Career/JoinToUs.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30vh;
    width: 100%;
}

.Join-Us {
    position: absolute;
    margin-top: 3.5rem;
    color: white;
    font-weight: 600;
    margin-left: 8.5%;
}

.LifeAt-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Join-Us h2 {
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 36px;
    
}

.SendE .SendEInner {
    padding: 10px 5px;
    margin-left: 0;
    max-width: 18%;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

iframe {
    width: 100% !important;
}


@media(max-width: 350px) {
    .post-title {
        font-size: 1rem;
    }
}

@media screen and (max-width: 766px) {
    .Career-section-home img {
        width: 100%;
        height: 200px !important;
        object-fit: cover;
    }

    .iframe {
        width: 100% !important;
        height: 200px !important;
    }

    .DeskHeading {
        margin: 0px;
    }
    .Career-overlay h2 {
        font-size: 30px;
    }

    .seAnnouncement h1 {
        font-size: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .DeskHeading {
        font-size: 30px;
    }

    .DeskHeading span {
        font-size: 30px;
    }

    .DeskVideoContainer h2 {
        font-size: 17px;
    }

    .LifeAt-row h1 {
        font-weight: 700;
        color: #0a3165;
        font-size: 30px;
        line-height: 37px;
    }

    .LifeAt-row h1 span {
        color: rgb(39 208 255);
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
    }

    .Join-Us h2 {
        font-size: 21px;
    }

    .SendE .SendEInner {
        max-width: 42% !important;
    }

    .seAnnouncement h1 span {
        font-size: 31px;

    }
}
 @media(max-width:425px){
    #carousel-item{
        margin-top: 5rem !important;
    }
    .DeskHeading {
        margin-left: 0px !important;
    }
 }


/* career carousel text section */

.career-carousel-text{
    position: absolute;
    bottom: 22%; 
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    color: white;
    text-align: start;
    font-size: larger;
    line-height: 1.5; 
    & h3{
        font-weight: bold; 
    }
    & p{
        margin-top: 2%;
        padding-right: 4%;
    }
}
#image1{
    bottom: 17%;
}

@media (max-width:1400px){
    .career-carousel-text{
        bottom: 16%;
        /* font-size: 120%;  */
    }
    #image1{
        bottom: 11%;
    }
}
@media (max-width:1200px){
    .career-carousel-text{
        bottom: 12%; 
        font-size: 100%;
    }
    #image1{
        bottom: 9%;
        font-size: 100%;
        & h3{
            font-size: 150%;
        }
    }
}
@media (max-width:992px){
    .career-carousel-text{
        font-size: 80% !important;
        & h3{
            font-size: 140% !important;
        }
    }
    #image1{
        bottom: 0%;
    }
}
@media (max-width:767px){
    .career-carousel-text{
        font-size: 60% !important;
    }
}
@media (max-width:592px){
    .career-carousel-text{
        font-size: 50% !important;
    }
}

@media (max-width:526px){
    .normal-carousel{
        display: none;
    }
}

/* Show .mobile-carousel below 526px */
@media (max-width: 525px) {
    .mobile-carousel {
        display: block; /* Ensure it's visible */
    }
}
/* Hide .mobile-carousel at 526px and above */
@media (min-width: 526px) {
    .mobile-carousel {
        display: none; /* Hide on larger screens */
    }
}
@media (max-width: 525px) {
    .LifeAt-row{
        margin-bottom: 2rem !important;
    }
    .mobile-carousel {
        
        & .carousel-inner{
            margin-top: -8.5rem !important;
        }
        & .card-body{
            background: linear-gradient(to right, #1a005e, #00d4ff) ;
            color: #fff;
        }
        & img{
            width: 100% !important;
        }
    }

    .mobile-carousel button {
        position: absolute;
        top: 15rem; /* Center vertically */
        transform: translateY(-50%); /* Adjust position */
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        
    }

    .mobile-carousel .carousel-control-prev {
        left: -3rem;
    }

    .mobile-carousel .carousel-control-next {
        right: -3rem; /* Adjust right position */
    }

    /* Change icon color */
    .mobile-carousel .carousel-control-prev-icon,
    .mobile-carousel .carousel-control-next-icon {
        background-color: black !important; 
    border-radius: 50%; 
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 9;
    filter: none !important;  
    }

}

