.footer-section {
  background-image: url("../../Assets/Images/Footer/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 30px 0;
  padding-right: 5%;
  padding-left: 5%;
}

.footer-section h6 {
  font-size: 24px;
  font-weight: 600 !important;
  margin-top: 6%;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.footer-section p {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.footer-logo {
  display: flex;
}

.footer-first-section {
  margin-left: 4%;
}

.footer-first-section h6 {
  font-size: 18px !important;
}

.footer-section .logo {
  display: flex;
  align-items: center;
}

.footer-section .logo h6 {
  font-size: 18px;
}

.footer-section .logo img {
  width: 80px !important;
  height: 80px !important;
}

.footer-logo-heading h2 {
  width: fit-content;  
}

.footer-second-section {
  margin-left: 30%;
}

.footer-third-section {
  margin-left: 44px;
}

.footer-second-section,
footer-menu p {
  cursor: pointer;
}


.footer-menu p {
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer-menu p:hover {
  transform: translateX(10px);
}

.footer-industries-menu p {
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.footer-industries-menu p:hover {
  transform: translateX(10px);
}

.footer-social-media i {
  font-size: 24px;
  color: white !important;
  transition: transform 0.3s ease;

}

.footer-social-media i:hover {
  transform: scale(1.2);
}

.footer-first-section {
  text-align: left;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-section .logo img {
  max-width: 100%;
}

.footer-section .logo h6 {
  margin-top: 10px;
}

.footer-social-media {
  display: flex;
  margin-top: 15px;
}

.footer-social-media i {
  font-size: 24px;
  color: #333;
  margin-right: 15px;
}

.footer-menu,
.footer-industries-menu {
  text-align: left;
}

.footer-menu h6,
.footer-industries-menu h6 {
  margin-bottom: 15px;
}

.footer-contact {
  text-align: left;
}

.footer-contact h6 {
  margin-bottom: 15px;
}

.footer-contact p {
  margin-bottom: 10px;
}

.ExportPrivacy {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

/* / Responsive adjustments / */
@media (max-width: 768px) {
  .footer-section {
    text-align: center;
    height: 100%;
  }

  .footer-third-section {
    margin-left: 0px;
  }



  .footer-first-section {
    margin-left: 0%;
  }

  .footer-section .logo {
    display: flex;
    align-items: center;
  }

  .footer-section .logo h6 {
    margin-left: 0%;
    font-size: 24px;
  }

  .footer-section .logo img {
    width: 40px;
    height: 40px;
  }

  .footer-second-section {
    margin-left: 0%;
  }
}
@media (max-width:425px){
  .footer-section .logo{
    margin-left: 0rem;
  }

}

@media(max-width:1400px){
  .footer-logo h4 {
    font-size: 1.3rem;
  }
  .footer-logo{
    margin-left: -1rem;
  }
}
@media(max-width:1200px){
  .footer-logo h4 {
    font-size: 110%;
    line-height: 1.6rem;
  }
} 
@media(max-width:1061px){
  .footer-logo h4 {
    font-size: 100%;
    line-height: 1.6rem;
  }
} 
@media(max-width:1009px){
  .footer-logo h4 {
    font-size: 90%;
    line-height: 1.6rem;

  } 
} 

