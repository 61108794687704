.demoPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 10px; */
  background-color: white;
  border: 1px solid #ccc;
}

.blur {
  filter: blur(5px);
  border: 1px solid black;
}

.image-wrapper {
  position: relative;
  width: 100%;
}

.subscribe-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  /* Semi-transparent dark background */
  color: white;
  /* Text color */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 2;
  /* Ensure it appears on top */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  /* Optional shadow for better visibility */
  width: 80%;
} 


body {
  font-family: Arial, sans-serif !important;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  
}


.TitleText {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  line-height: 1.8;
  word-spacing: 0.05em;
  letter-spacing: 0.02em;
  border-radius: 8px;
  padding: 1rem;
  max-width: 900px;
  margin-left: auto; /* Aligns to the right */
  margin-right: 0; /* Ensures it stays at the right */
  text-align: right; /* Aligns text content to the right */
}


.eachBook {
  display: flex;
  justify-content: center;
  margin: 0px 25px ;
  overflow: hidden;
  width: 40vw;
}

@media screen and (max-width:786px) {
  .eachBook{
    width: 100%;
  }
}