.product-section h1 {
  font-size: 40px;
  font-weight: 700;
  color: #2D67AA;
}

.product-section span {
  color: rgb(58, 196, 242);
}

.product-row {
  margin-left: 5% !important;
}


.product-section .card {
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease;
}


.card .arrow {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.arrow {
  cursor: pointer;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow i {
  text-align: center;
}

.card:hover .arrow {
  display: flex;
}

.product-section .card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(141, 141, 250, 0.5);
  z-index: 1;
}

.card-body {
  z-index: 2;
}

.card-button {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  background-color: #004a98;
  color: #fff;
  border: none;
  z-index: 5;
  cursor: pointer;
  text-align: center;
  display: block;
  text-decoration: none;
}


/* Ensure the container has relative positioning */
.home-product-image-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

/* Product Image Style */
.home-product-image {
  height: auto;
  max-width: 100%;
  width: 100%;
  display: block;
  transition: filter 0.5s ease;
}

/* Overlay Text Style */
.home-overlay-text {
  position: absolute;
  bottom: -40%;
  left: 0;
  right: 0;
  background-color: rgba(0, 140, 194, 0.85);
  color: white;
  text-align: center;
  opacity: 0;
  height: 40%;
  width: 100%;
  transition: bottom 0.5s ease, opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
}

/* Inner Text Style */
.home-overlay-inner-text span {
  font-weight: bold;
  font-size: 15px;
  color: #fff !important;
}

/* On Hover */
.home-product-image-container:hover .home-product-image {
  filter: brightness(0.7);
}

.home-product-image-container:hover .home-overlay-text {
  bottom: 0;
  opacity: 1;
  z-index: 0;
}


@media (max-width: 1024px) {

  .product-section .card {

    position: relative;
    overflow: hidden;
    width: 80% !important;
    transition: background-color 0.5s ease;
  }
}

@media (max-width: 767px) {


  .product-section .card {
    width: 100% !important;
    height: 100%;
  }

  .product-row {
    margin-left: 0% !important;
  }

  .home-product-image {
    height: 270px;
    width: 280px;
  }

}