.service-section {
    position: relative;
    text-align: center;
}

.service-section-2 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.service-section .service-bg-img {
    position: relative;
    overflow: hidden;
}

.service-section .img-fluid {
    width: 100%;
    height: auto;
    display: block;
    /* margin-top: 8.2rem; */
}

.service-section.text-overlay {
    position: absolute;
    top: 40%;
    left: 80%;
    color: white;
}

.text-overlay h1 {
    margin-left: 15%;
    width: 100%;
}

.subeader-content {
    padding: 205px 0 160px;
}

.subeader-content h1 {
    font-size: 40px;
    margin-top: 4%;
    margin-bottom: 5px;
    color: #fff;
}
.ser-img1{
    display: flex;
    align-items: start;
    justify-content: center;
    padding-top: 2rem !important;
}
.ser-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ser-left-img {
    width: 80% !important;
    border-radius: 10px;
}
.sub-ser-img{
    width:80% !important;
}
.Ser-Header {
    color: #2460a6;
    font-weight: 700;
    text-align: left;
}

.ss-text-inner {
    width: 80%;
    text-align: justify;
    font-size: 14px;
}


.service-setion-3 {
    background-color: #f5f6f7;
    padding-top: 20px;
}

.service-section-4 {
    background-color: #f5f6f7;
    padding-top: 30px;
}

.service-section-5 {
    background-color: #f5f6f7;
    padding-top: 50px;
    padding-bottom: 50px;
}

.ser-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* ----------------- */


.slide img {
    width: 100%;
    height: 100%;
}

.image-slider .image-text-overlay {
    position: absolute;
    top: 70%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    margin-left: 6rem;
    font-size: 15px;
    white-space: nowrap; /* Ensures text stays in one line */
    display: flex;
    justify-content: center;

}

.image-slider .image-text-overlay p {
    margin: 0;
    padding: 0;
}
 

.slider {
    overflow: hidden;
    width: 100%;
}

.slide-track {
    display: flex;
    animation: slideAnimation 30s linear infinite;
}

.slide {
    flex: 0 0 250px;
}


.image-slider {
    width: 100% !important;
    margin: 5px;

}

.image-slider img{
    height: 100% !important;
}

@keyframes slideAnimation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}



.service-section-2 img {
    width: 100%;
}

/* Responsive font size */
@media (max-width: 768px) {
    .text-overlay h1 {
        font-size: 1em;
    }

    .text-overlay h1 {
        margin-left: 0%;
        width: 100%;
    }

    .service-section.text-overlay {
        position: absolute;
        top: 20%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
    }

    .service-section .img-fluid {
        width: 100%;
        height: 100%;
        display: block;
    }
}


@media screen and (max-width: 991px) {
    .oddSection {
        display: flex;
        flex-direction: column-reverse;
    }
    .ser-img1{
        padding-top: 0rem !important;
    }
    .service-setion-3 {
        margin-top: -1rem;
    }
}
