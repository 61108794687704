.about-section-home img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.about-section-home .text-overlay h2 {
  margin-left: 20%;
  width: 100%;
}

.infra-readMore .inner-infra {
  padding: 12px;
  margin-left: 0;
  width: 50%;
  background-color: transparent;
  border: 3px solid #3Ac4f2;
  color: #3Ac4f2;
  font-weight: 600;
  font-size: 19px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


.about-section-img {
  position: sticky;
}
.img-fluid{
  margin-top: 8.3rem;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.text-overlay h2 {
  font-size: 3rem;
  margin: 0;
}

/* Default styles */
.about-section-second-heading {
  display: flex;
  flex-direction: row;
}

.about-section-second-heading h2 {
  color: #0A3166;
  font-weight: bold;
}

.about-section-second-heading .purpose {
  font-weight: bold;
  color: #3Ac4f2;
}


.about-section-second-heading {
  margin-top: 5%;
}


.about-section-second-para p {
  text-align: justify;
}

.about-section-second-icons-section {
  display: flex;
  justify-content: space-around;
}

.icons {
  display: flex;
  align-items: center;
  min-height: 100%;
}


.icon-container i {
  height: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 10px;
  margin: 10px;
  border: 2px solid blue;
}

.fa-check {
  color: #3Ac4f2;
}

.about-section-second-icons-section,
.icons {
  display: flex;
}

.icons h5 {
  color: #0A3166;
  font-size: 1.2rem;
  font-weight: bold;
}

.about-section-third-opportunity {
  margin-top: -7%;
}

.about-section-third-opportunity .heading {
  display: flex;
  margin-bottom: 14px;
}

.about-section-third-opportunity h2 {
  color: #0A3166 !important;
  font-weight: bold;
}

.about-section-third-opportunity .opportunity {
  color: #3Ac4f2 !important;
  font-weight: bold;
}

.about-section-third-opportunity .heading-para p {
  text-align: justify;
  color: #212529;
}

.heading-button button {
  background-color: white;
  border: 2px solid #004A98;
  color: #004A98;
  padding: 10px 20px;
  cursor: pointer;
  width: 25%;
}

.heading-button {
  margin-left: 5%;
}


.about-section-third-opportunity-img img {
  width: 30%;
  height: 40%;
}

.about-section-third-opportunity-img {
  display: flex;
}

.third-img img {
  width: 100%;
  height: 100%;
  margin-left: -15rem;
}

.last-img img {
  height: 100%;
}

.about-section-mission {
  margin-top: 3%;
}

.card{
  z-index: 0;
}
.about-section-mission .card {
  min-height: 309px;
  border: 2px solid #004A98;
}
@media (max-width:1400px){
  .wordspacing{
    word-spacing: 3rem ;
  }
}
@media (max-width:992px){
  .wordspacing{
    word-spacing: 0rem ;
  }
}

@media (max-width:1280px) {
  .about-section-mission .card {
    height: 430px;
  }
}

.about-section-mission .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1024px) {

  .about-section-mission .card {
    width: 100%;
    height: 85%;
  }

  .about-section-mission .card-body {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    align-items: center;
  }
}



.about-section-mission .card-body p {
  text-align: center;
}

.about-section-mission .card-body a {
  margin-top: 0%;
}

.about-section-mission .card-body .custom-button {
  display: inline-block;
  padding: 6px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #0A3166;
  color: #fff;
  border: 1px solid #007bff;
  /* width: 70%; */
  border-radius: 5px;
}


.about-section-mission-img {
  display: flex;
  justify-content: center;
  align-items: center;

}

.about-section-mission-img img {
  background: none;
  border: none;
  width: 30%;
  height: 30%;
  margin-bottom: 10%;
}

.last-mg {
  height: 100%;
}

.lastimg {
  align-items: center;
}

/* why choose us section :start */
.whychoose-sections {
  padding-bottom: 50px;
  background-color: #fff;
  padding-top: 40px;
}

.whych-header {
  font-size: 36px;
  font-weight: 900;
  margin-top: 40px;
  color: rgb(10 49 102);
}

.why-leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.why-leftSide h2 {
  color: #0A3166;
  font-size: 37px;
  font-weight: 700;
  padding-bottom: 10px;
}

.why-leftSide h2 span {
  color: #3Ac4f2;
  font-size: 37px;
  font-weight: 700;
  padding-bottom: 10px;

}

.card-section {
  text-align: center !important;
  border: 2px solid rgb(10 49 102);
  border-radius: 10px;
  height: 100%;
  width: 80%;
  background-color: rgb(10 49 102);
}

.card-section .card-row1 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  text-align: start;
}

.col-icon {
  margin-top: 4px;
  margin-left: 10px;
  padding-right: 10px;
}

.col-para {
  padding-left: 20px;
}

.card-row1 p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.section2-col-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section2-col-2 img {
  height: 80%;
  max-width: 100%;
  width: auto;
}

.our-infrastructure-section .infra-heading h1 {
  font-size: 37px;
  font-weight: 700;
  padding-bottom: 10px;
  color: #0A3166;
}


/* / material-section / */
.material-section {
  padding: 10px;
  background-color: rgb(10 49 102);
}

.section-title {
  margin-bottom: 30px;
}

.section-title h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-bottom: 25px;
}

.m-image {
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

.m-image img {
  width: 100%;
  display: block;
  height: 40vh;
  object-fit: cover;
}

.lower-content-brass {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(240, 197, 97);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.lower-content-steel {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(198, 198, 198);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.lower-content-copper {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(222, 166, 125);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.lower-content-Alu {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(198, 198, 198);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.lower-content-titanium {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(190, 190, 190);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.lower-content-Inconel {
  text-align: center;
  padding: 20px 20px 5px;
  background-color: rgb(150, 150, 150);
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 4px solid transparent;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}


.m-image img {
  transition: transform 0.3s ease-in-out;
}

.lower-content-Alu {
  transition: background-color 0.3s ease-in-out;
}

.team-block:hover .m-image img {
  transform: scale(1.1);
}

/* our infrastructre section start */
.our-infrastructure-section .infra-heading {
  display: flex;
  flex-direction: row;
}

.our-infrastructure-section .infra-heading .infra {
  margin-left: 2%;
  color: #3Ac4f2 !important;
  font-size: 37px;
  font-weight: 700;
}

.infra-para {
  width: 95%;
  margin-bottom: 40px;
}

.infra-para p {
  color: black;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
}

.infra-img-section img {
  border: none;
  object-fit: cover;
}


@media (max-width:768px) {
  .mission-col {
    margin-top: 5rem;
  }
  .about-section-mission .card {
    width: 100%;
    height: 60%;
  }
  .mission-col1 {
    margin-top: 5rem;
  }
}

@media (max-width:821px) {
  .mission-col {
    margin-top: 5rem;
  }
}

@media (max-width:376px) {
  .mission-col1 {
    margin-top: 6rem;
  }
}

@media(max-width:541px) {
  .mission-col1 {
    margin-top: 5rem;
  }
}

@media (min-width:389px)and (max-width:432px) {
  .mission-col1 {
    margin-top: 6rem;
  }

  .about-section-mission .card {
    width: 100%;
    height: 60%;
  }

}

@media (max-width:376px) {
  .mission-col {
    margin-top: 3rem;
  }
}

@media (max-width:992px) {
  .mission-col {
    margin-top: 5rem;
  }
}


/* 
@media (max-width:768px) {
  .mission-col {
    margin-top: 9.5rem;
  }


} */

/* Media query for small devices (e.g., smartphones) */


@media only screen and (max-width: 767px) {

  .whychoose-sections {
    margin-top: 0%;
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .about-section-home .text-overlay h2 {
    margin-left: 0px !important;
  }

  .whych-header {
    font-size: 28px;
    margin-top: 20px;
  }

  .section4-row2 {
    grid-template-columns: auto;

  }

  .card-section {
    width: 100%;
    margin: 20px 0;
  }

  .card-section .card-row1 {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .col-para {
    padding-left: 0;
  }

  .card-row1 p {
    margin-top: 10px;
    padding-top: 26px;
  }

  .section2-col-2 img {
    height: 100%;
  }

  .material-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-title h2 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .m-image img {
    height: 100%;
  }

  .lower-content-brass,
  .lower-content-steel,
  .lower-content-copper,
  .lower-content-Alu {
    padding: 10px;
  }

  .section2-col-2 img {
    margin-top: 10%;
  }

  .about-section-home img {
    width: 100%;
    height: auto;
  }



  .infra-readMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .about-section-second-heading {
    display: flex;
    flex-direction: column;
  }

  .text-overlay {
    left: 50%;
    transform: translateX(-50%);
  }

  .text-overlay h2 {
    font-size: 2rem;
  }

  .about-section-second img {
    width: 100%;
  }

  .icons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-container {
    padding: 2px;
    height: 4vh;
    width: 30%;
  }

  .icons h5 {
    color: #0A3166;
    font-size: 1rem;
    font-weight: bold;
  }

  .about-section-third-opportunity .heading {
    display: flex;
    flex-direction: column;
  }

  .heading {
    margin-left: 0%;
  }

  .heading-para {
    margin-left: 0%;
  }

  .heading-button {
    margin-left: 0%;
  }

  .heading-button button {
    width: 100%;
    margin-bottom: 2%;
  }
  
  .about-section-mission .card {
    /* width: 100%; */
    border: 2px solid #004A98;
  }

  .about-section-mission p {
    font-weight: 500;
    font-weight: 500;
  }

  /* Our Infrastructure section start */
  .our-infrastructure-section .infra-img-section img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .newsLetterWrapper{
    flex-wrap: wrap;
  }
}
.newsLetterWrapper{
  display: flex;
  justify-content: center;
  width: calc(100vw - 100px);
  padding:30px 0px;

}

/* table css */

.table-heder-name {
  background-color: #0472C2;
  text-align: start;
  color: white;
  padding: 10px 0px 10px 5px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
   
}

.th {
  padding: 10px 20px; 
  text-align: center;
  border: 1px solid white ;
  background-color: #0472C2 !important; 
  color: white !important; 
}

.make {
  width: 20%;
}

#serial-no{
  text-align: center;
  border: 1px solid white;
  background-color: #bfccd0; 
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #000055 !important;
  font-family: "Poppins", serif !important;
}

.td {
  text-align: left;
  border: 1px solid white; 
  margin-left: 50px;
  background-color: #bfccd0; 
  color: black; 
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000055 !important;
  font-family: "Poppins", serif !important;

}

.thead {
  position: sticky;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  
  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 14px;
  }

  .th, .td {
    padding: 8px; 
  }

  .th {
    font-size: 16px; 
  }
 
  .make {
    width: 30% !important;
  }

  
  .th, .td {
    border: 1px solid #fff;
    padding: 3px !important;
  }
  
}

@media (max-width:425px){
  .img-fluid{
    margin-top: 5rem;
  }
}
