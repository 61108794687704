.event-section {
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
}
.events-section-headings{    
  color: #0A3166 ;
  font-size: 36px;
  font-weight: 600;
  /* margin-bottom: 2rem; */
}
.EventText h2{
  color: black;
}
.sub-header h1 {
  font-size: 3rem;
  color: white;
  /* Ensure the text is white for visibility */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  /* Add text shadow for contrast */
  text-align: center;
}
.events-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* Ensures cards stretch to the same height */
  gap: 20px;
}
/* EventDescription specific styles */
.event-description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 auto;
}

/* Styling for the title */
.event-description-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #222222;
  /* margin-bottom: 1rem; */
  text-align: center;
}

/* Styling for the image */
.event-description-container .bannerimg {
  /* width: 98vw; */
  width: 98vw;
  height: auto;
  padding-top:6rem ;
  
}
.event-description-container .inner-event-img{
  border: 2px solid yellow;
}

/* Styling for date and subtitle */
.event-description-container h6 {
  font-size: 1.1rem;
  color: #666666;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.event-description-container h5 {
  font-size: 1.25rem;
  color: #444444;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Styling for the event description text */
.EventText {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  font-size: 1.1rem; /* Base font size */
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
  line-height: 1.8; /* Increased line height */
  text-align: justify;
  word-spacing: 0.05em;
  letter-spacing: 0.02em;
  background-color: #f9f9f9;
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
}

/* Links inside the description */
.EventText a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s ease;
}

.EventText a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
 .event-section{
  height: 160px;
  & h1 {
    margin-top:0rem !important;
    padding-left:0rem !important;
  }
 }
 .sub-header{
  padding-top: 165px;
 }
  .event-description-container {
    padding: 0.5rem;
  }

  .event-description-container h1 {
    font-size: 2rem;
  }

  .EventText {
    font-size: 1rem;
    padding: 0.5rem;
  }
  .EventText p{
    font-size: 1rem;
    padding: 0px !important;
  }
  .event-banner{
    padding-top: 8rem !important;
  }
  .event-banner .text-overlay{
    font-size: large;
    /* transform: translate(-50%,100%); */
    top: 70% !important;

  }
}

@media (min-width: 2560px){
  .event-description-container .bannerimg{
    /* width: 98vw; */
    width: auto !important;
    height: auto;
  }
}

.event-banner {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 8.8rem;
}

.event-banner img {
  width: 100%;
  height: auto;
  filter: brightness(50%);
  object-fit: cover;
}

.event-banner .text-overlay {
  position: absolute;
  color: white;
  font-size: 300%;
  font-weight: bold;
}

@media(max-width:425px){
  .event-banner{
    padding-top: 5.5rem !important;
  }
  .event-banner .text-overlay{
    font-size: large;
    left:50%;
    top: 54% !important;
  }
  .event-description-container .bannerimg{
    padding-top: 4.5rem;
  }
}
