.certificate-heading {
    color: black !important;
}

.downloadPdf {
    cursor: pointer !important;
}

.certificate-component {
    margin-top: -5%;
    
}

.new-entry-title {
    margin-top: -2%;
}

.certificate-component img {
    width: 70%;
    height: 80%;
    margin-top: 0rem;
    margin-bottom: -1rem;
    transform: translatey(10%);
}

