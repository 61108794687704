.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  .form-container {
    margin-top: 8.3rem !important;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    width: 390px;
    height: 60vh;
    padding: 20px;
  }
  @media screen and (max-width: 480px) {
    .form-container {
      height: 65vh;
    }
  }
  
 .text-h1 {
    font-size: 24px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
  }
  
  .label {
    font-weight: bold;
  }
  
  .input-field {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 87%;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    width: 47%;
  }
  
  .submit-button:hover {
    background-color: #052c95;
  }
  
  .error-message {
    color: red;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .visible-button {
    position: absolute;
    margin-top: 122px;
    margin-left: 259px;
    padding: 9px;
    border: none;
  }