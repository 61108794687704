.image-blog-header-blog3 {
    width: 100%;
    height: 427px;
    margin-top: -50px;
    background-image: url('../../../Assets/Images/blog/Overcoming.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}