.InnerCasting-Section {
    background-image: url("../../../../../Assets//Images/Products/castingbanner.webp");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.InnerCasting-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.InnerCasting-Section .casting-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.casting-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

.Access-Section-2 {
    padding: 106px 0px;
}

@media (max-width: 786px) {
    .InnerCasting-Section .casting-subheader-content {
        padding: 100px 0 160px 0 !important;
    }

    .casting-subheader-content h1 {
        font-size: 46px;
        margin-top: 3%;
        margin-bottom: 5px;
        color: #fff;
        font-weight: 600;
    }

}