.Electrical-Section {
    background-image: url("../../../../Assets//Images//Products/FastnerBanner.webp");
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    transition: border 0.3s ease-in, box-shadow 0.3s ease-in, background 0.3s, opacity 0.3s ease-in;
}

.Terminal-section h1 {
    font-size: 42px;
    color: #14212b;
    margin-bottom: 30px;
}

.Electrical-Section::before {
    content: "";
    background-color: #14212b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.Electrical-Section .El-subheader-content {
    position: relative;
    padding: 205px 0 160px;
}

.El-subheader-content h1 {
    font-size: 50px;
    margin-top: 3%;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 600;
}

/* Terminal-section */

.Terminal-section {
    padding: 50px 0px;
}

.inner-box {
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px #00000017;
}

.animation-img {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s;
}

.animation-img:hover {
    transform: scale(0.9);
}

.fastner-img-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.left-side {
    row-gap: 10px;
}

.left-side h3 {
    font-size: 18px;
}

@media (max-width: 767px) {
    .Electrical-Section .El-subheader-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding: 128px 0 160px;
    }

    .El-subheader-content h1 {
        color: #fff;
        font-weight: 600;
        font-size: 40px;
    }
}